<template>
    <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'issue-redirect',
    data: () => ({}),
    created() {
        this.fetchIssue();
    },
    watch: {
        issue: function (i) {
            if (i) {
                const project = this.projects.find((p) => p.name === i.project);

                this.$router.push({
                    name: 'project-issue',
                    params: { project_id: project.id, issue_id: i.id },
                });
            }
        },
    },
    computed: {
        ...mapGetters(['profile', 'issue', 'projects']),
    },
    methods: {
        ...mapActions(['findIssue']),
        fetchIssue() {
            const idToGet = this.$route.params.issue_id;
            this.findIssue(idToGet);
        },
    },
};
</script>

<style></style>
